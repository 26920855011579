
























































































































































































/* eslint-disable no-param-reassign */
import { Vue, Watch } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Component from 'vue-class-component';
import PriceFilter from '@/modules/common/filters/price.filter';
import Demand from '@/modules/common/components/ui-kit/demand.vue';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import Currency from '@/modules/common/components/currency.vue';
import AllChannelCell from '@/modules/di-lite/components/all-channels/all-channel-cell.vue';
import CIUpgradeCard from '@/modules/di-lite/components/all-channels/ci-upgrade-card.vue';
import DiLiteAllChannelsService, { DiLiteAllChannelsServiceS } from '@/modules/di-lite/di-lite-all-channels.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import Day from '@/modules/common/types/day.type';

@Component({
    components: {
        Demand,
        ProviderCard,
        CIUpgradeCard,
        AllChannelCell,
        Currency,
    },
    filters: {
        PriceFilter,
    },
})
export default class AllChannels extends Vue {
    @Inject(HelperServiceS) helperService!: HelperService;
    @Inject(DiLiteAllChannelsServiceS) diLiteAllChannelsService!: DiLiteAllChannelsService;

    readonly brand = 'bcom';

    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.makeColumnsSticky();
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        const wrapper = this.$refs.bodyWrapper as HTMLElement;
        if (wrapper) {
            wrapper.scrollTop = 0;
        }

        this.makeColumnsSticky();
    }

    getDemandValue(index: number) {
        const { demandData } = this.diLiteAllChannelsService;

        return demandData.length ? demandData[index] : null;
    }

    diff(day: string) {
        const maxDiff = this.diLiteAllChannelsService.getMaxDiff(day);
        return maxDiff ? maxDiff.diff : null;
    }

    absDiff(day: string) {
        const maxDiff = this.diLiteAllChannelsService.getMaxDiff(day);
        return maxDiff ? maxDiff.diffRatio : null;
    }

    formatPercent(value: number | string, maxFixed: number = 2, minFixed: number = 0) {
        return Number(value).toLocaleString('en-US', { maximumFractionDigits: maxFixed, minimumFractionDigits: minFixed });
    }

    handleProviderClick(provider: string) {
        if (!this.diLiteAllChannelsService.isBrandAvailable) {
            this.diLiteAllChannelsService.provider = provider;
        }
    }

    isProviderSelected(provider: string) {
        return this.diLiteAllChannelsService.provider === provider;
    }

    getDateByDay(day: number) {
        return this.diLiteAllChannelsService.getDateByDay(day as Day);
    }

    get isLoading() {
        const { data, compset, isLoading } = this.diLiteAllChannelsService;

        return (!data || !compset) || isLoading;
    }

    get providers() {
        if (this.isLoading) {
            return Array.from({ length: 5 });
        }
        return this.diLiteAllChannelsService.providers;
    }

    @Watch('providers')
    async makeColumnsSticky(): Promise<void> {
        const { table } = this.$refs as { table: HTMLDivElement };

        if (!table) return;

        if (this.isLoading) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.makeColumnsSticky();
            return;
        }

        table.scrollLeft = 0;

        const elements = Array.from(
            table.querySelectorAll('[data-make-sticky]'),
        ) as HTMLElement[];

        const columnWidth = [0, 0, 0, 0];

        elements.forEach((el, i) => {
            const columnIndex = i % 4;
            el.style.width = '';
            el.style.left = '';
            columnWidth[columnIndex] = columnWidth[columnIndex] || el.offsetWidth;
        });

        elements.forEach((el, i) => {
            const columnIndex = i % 4;
            el.style.position = 'sticky';
            el.style.width = `${columnWidth[columnIndex]}px`;
            el.style.zIndex = el.dataset.isupgradecell ? '3' : '2';
        });

        elements.forEach(el => {
            el.style.left = `${el.offsetLeft}px`;
        });
    }
}
