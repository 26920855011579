






























import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import AllChannels from '@/modules/di-lite/components/all-channels/all-channels.vue';
import RatesExcelBtn from '@/modules/rates/components/rates-excel-btn.vue';
import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip.vue';
import ExcelBtn from '@/modules/common/components/excel-btn.vue';
import DiLiteMarketService, { DiLiteMarketServiceS } from '../../di-lite-market.service';
import AllChannelsFilters from './filter-bar.vue';

@Component({
    components: {
        AllChannels,
        AllChannelsFilters,
        RatesExcelBtn,
        CustomTooltip,
        ExcelBtn,
    },
})
export default class AllChannelsSection extends Vue {
    @Inject(DiLiteMarketServiceS)
    private diLiteMarketService!: DiLiteMarketService;

    get isExcelLoading() {
        return this.diLiteMarketService.isExcelLoading;
    }

    // TODO: Remove this methot at all since the logic moved into popup
    // handleExcelClick() {
    //     if (this.isExcelLoading) {
    //         return;
    //     }

    //     // this.diLiteMarketService.downloadExcel();
    // }
}

