














import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';

@Component
export default class CIUpgradeCard extends Vue {
    @Inject(ConfigServiceS) private configService!: ConfigService;
}
