














import AllChannelsSection from '@/modules/di-lite/components/all-channels/all-channels-section.vue';
import MarketSection from '@/modules/di-lite/components/market/market-section.vue';
import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import DiLiteHeader from '@/modules/di-lite/components/di-lite-header.vue';

@Component({
    components: {
        PageWrapper,
        DiLiteHeader,
        AllChannelsSection,
        MarketSection,
    },
})
export default class DiLitePage extends Vue {

}
