






















import DIUpgradeCard from '@/modules/di-lite/components/market/di-upgrade-card.vue';
import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';

@Component({
    components: {
        CustomGraph,
        DIUpgradeCard,
    },
})
export default class TopWholesalers extends Vue {
    @Prop({
        required: true,
        type: null as unknown as undefined,
        default: () => null,
    })
    public data!: { [name: string]: number } | null;

    @Prop({
        required: true,
        type: Boolean,
    })
    public loading!: boolean;

    get chartData() {
        const sourceData = this.data || {};

        let top10Data = Object.entries(sourceData);
        top10Data.sort((a, b) => b[1] - a[1]);
        top10Data = top10Data.slice(0, 10);

        const labels = top10Data.map(([label]) => label);
        const data = top10Data.map(([, value]) => value);
        return {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor: '#66A2EB',
                    barThickness: 5.7,
                },
            ],
        };
    }

    get max() {
        return this.data
            ? Object
                .values(this.data)
                .sort((a, b) => b - a)[0]
            : null;
    }

    get options() {
        return {
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 130,
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        borderDash: [4, 4],
                        borderWidth: 3,
                        borderColor: '#ECF1F5',
                        zeroLineColor: '#ECF1F5',
                        drawBorder: false,
                    },
                    ticks: {
                        stepSize: 10,
                        min: 0,
                        max: this.max,
                    },
                }],
                yAxes: [{
                    gridLines: {
                        borderColor: '#ECF1F5',
                        zeroLineColor: 'transparent',
                        color: 'transparent',
                        drawBorder: false,
                    },
                }],
            },
        };
    }
}
