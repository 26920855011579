import Price from '@/modules/common/types/price.type';

export default function PriceFilter(value: Price | string, fractionDigits :number = 2) {
    const defaultFractionDigits = fractionDigits;

    // if (Number(Number(value).toFixed(fractionDigits)) >= 100 || Number(value) % 1 === 0) {
    //     defaultFractionDigits = 0;
    // }
    const formattedValue = Number(value)
        .toLocaleString('en-US', { maximumFractionDigits: defaultFractionDigits, minimumFractionDigits: 0 });

    return formattedValue;
}
