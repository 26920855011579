













































import { Component, Mixins } from 'vue-property-decorator';
import Select from '@/modules/common/components/ui-kit/custom-select.vue';
import TogglePrices from '@/modules/common/modules/rates/components/toggle-prices.vue';
import DiLiteFilterItemsMixin from '../../mixins/filter-items.mixin';

@Component({
    components: {
        Select,
        TogglePrices,
    },
})
export default class DiLiteAllChannelsFilters extends Mixins(DiLiteFilterItemsMixin) {
    get filterState() {
        return this.allChannelsService.filters;
    }

    get pos() {
        return this.allChannelsService.filters.pos;
    }

    set pos(newValue) {
        this.allChannelsService.filters.pos = newValue;
    }

    get device() {
        return this.allChannelsService.settings.device;
    }

    set device(newValue) {
        this.allChannelsService.settings.device = newValue;
    }

    get los() {
        return this.allChannelsService.filters.los;
    }

    set los(newValue) {
        this.allChannelsService.filters.los = newValue;
    }

    get ng() {
        return this.allChannelsService.settings.numberOfGuests;
    }

    set ng(newValue) {
        this.allChannelsService.settings.numberOfGuests = newValue;
    }

    get priceType() {
        return this.allChannelsService.settings.priceType;
    }

    set priceType(newValue) {
        this.allChannelsService.settings.priceType = newValue;
    }

    get roomType() {
        return this.allChannelsService.settings.roomTypeId;
    }

    set roomType(newValue) {
        this.allChannelsService.settings.roomTypeId = newValue;
    }

    get mealType() {
        return this.allChannelsService.settings.mealTypeId;
    }

    set mealType(newValue) {
        this.allChannelsService.settings.mealTypeId = newValue;
    }
}
