import { Inject } from 'inversify-props';
import { Vue, Component } from 'vue-property-decorator';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';
import DiLiteAllChannelsService, { DiLiteAllChannelsServiceS } from '../di-lite-all-channels.service';
import { DILiteFilterDevice } from '../models/all-channels-settings.model';

@Component
export default class DiLiteFilterItemsMixin extends Vue {
    @Inject(RoomTypesServiceS)
    protected roomTypesService!: RoomTypesService;

    @Inject(MealTypesServiceS)
    protected mealTypesService!: MealTypesService;

    @Inject(DiLiteAllChannelsServiceS)
    protected allChannelsService!: DiLiteAllChannelsService;

    get priceTypeItems() {
        return [
            {
                name: 'Lowest',
                value: PRICE_TYPE.LOWEST,
            },
            {
                name: 'Lowest Flex',
                value: PRICE_TYPE.LOWEST_FLEX,
            },
            {
                name: 'Best Flex',
                value: PRICE_TYPE.BEST_FLEX,
            },
            {
                name: 'Non Refundable',
                value: PRICE_TYPE.NON_REFUNDABLE,
            },
        ];
    }

    get losItems() {
        const { compset } = this.allChannelsService;

        const losList = compset
            ? compset.los
            : [1];

        return losList
            .map(e => ({
                value: e,
                name: e + (e > 1 ? ' nights' : ' night'),
            }));
    }

    get posItems() {
        const { compset } = this.allChannelsService;

        const posList = compset
            ? compset.pos
            : ['US'];

        return posList.map(name => ({ name, value: name }));
    }

    get numberOfGuestsItems() {
        return Array
            .from({ length: 10 })
            .map((_, i) => i + 1)
            .map(e => ({
                value: e,
                name: e + (e > 1 ? ' guests' : ' guest'),
            }));
    }

    get roomTypeItems() {
        if (!this.roomTypesService.rooms) {
            return [{
                value: ANY_ROOM_TYPE.id,
                name: ANY_ROOM_TYPE.name,
            }];
        }

        return this.roomTypesService.rooms
            .filter((roomType: RoomTypeModel) => !!roomType.name)
            .map(
                (roomType: RoomTypeModel) => ({
                    value: roomType.id,
                    name: roomType.name,
                }),
            );
    }

    get mealTypeItems() {
        const { mealTypes } = this.mealTypesService;
        if (!mealTypes) {
            return [];
        }
        return mealTypes.map((mealType: MealTypeModel) => ({
            value: mealType.id as (number | string),
            name: mealType.displayName,
        }));
    }

    get devicesItems() {
        return [
            {
                value: DILiteFilterDevice.ALL,
                name: 'All devices',
            },
            {
                value: DILiteFilterDevice.DESKTOP,
                name: 'Desktop',
            },
            {
                value: DILiteFilterDevice.MOBILE,
                name: 'Mobile app',
            },
        ];
    }
}
